import config from '../../config'
import { TezosToolkit, MichelCodecPacker, OpKind } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'

const tezos = new TezosToolkit(config.endpoint);
tezos.setPackerProvider(new MichelCodecPacker());
const wallet = new BeaconWallet(config.walletOptions);
tezos.setWalletProvider(wallet);

const subscribeOperation = tezos.stream.subscribeOperation({
  and: [
    { destination: config.crowdsale }, // must be our action contract
    { kind: OpKind.TRANSACTION }
  ]
})


const pool = {}

async function getContract (kt) {
  if (!pool[kt]) {
    pool[kt] = tezos.wallet.at(kt)
  }
  return await pool[kt]
}

const authWallet = {
  namespaced: true,
  state: {
    userAddress: null,
    isConnected: false,
    contractStorage: {}
  },
  actions: {
    async init ({ commit }) {
      if (await wallet.client.getActiveAccount()) {
        const address = await wallet.getPKH()
        commit('setUserAddress', address)
      }
      subscribeOperation.on('data', data => {
        console.log('mint:', data.parameters?.entrypoint === 'mint')
      })
    },
    async connectWallet ({ state, commit, dispatch }) {
      let activeAccount = await wallet.client.getActiveAccount()
      try {
        if (!activeAccount) {
          await dispatch('disconnectWallet')
          await wallet.requestPermissions({
            network: {
              type: config.network
            }
          })
          activeAccount = await wallet.client.getActiveAccount()
          if (!activeAccount) {
            throw new Error('Wallet not connected')
          }
        }
        const address = await wallet.getPKH()
        commit('setUserAddress', address)
      } catch (e) {
        await dispatch('disconnectWallet')
      }
      return !!state.userAddress
    },
    async disconnectWallet ({ commit }) {
      await wallet.clearActiveAccount()
      commit('setUserAddress', null)
    },
    async startAuction ({dispatch }, params) {
      try {
        const amount = params.amount;
        const contractId = config['contract_' + params.auction + 'years'];
        await dispatch('connectWallet');
        const contract = await getContract(contractId);
        const op = await contract.methods.make_bid().send({ amount, mutez: false });
        const result = await op.confirmation();
        if (result.completed) {
          return true
        }
        throw new Error('Mint transaction failed')
      } catch (e) {
        if (e.title === 'Aborted') return false
        else throw e
      }
    },
    async updateTransactions ({ commit , dispatch}, params) {
      const amount = params.amount;
      const contractId = params.contract
      await dispatch('connectWallet');
      const contract = await getContract(contractId);
      const op = await contract.methods.add_funds(+params.id).send({ amount, mutez: false });
      const result = await op.confirmation();
      commit('setTransactions', result)
    },
    async withdrawTransaction ({dispatch}, params) {
      const contractId = params.contract
      await dispatch('connectWallet');
      const contract = await getContract(contractId);
      await contract.methods.cancel_failed_bid(params.id).send()
      return true
    },
    async takeNftResolve ({dispatch}, params) {
      const contractId = params.contract
      await dispatch('connectWallet');
      const contract = await getContract(contractId);
      const res =  await contract.methods.resolve(params.id).send()
      return await res.confirmation();
    }
},
  mutations: {
    setUserAddress (state, data) {
      if (data) {
        state.isConnected = true
      } else {
        state.isConnected = false
      }
      state.userAddress = data
    }
  },
  getters: {
    auctions: state => state.auctions,
    userAddress: state => state.userAddress,
    isConnected: state => state.isConnected
  }
}
export default authWallet
