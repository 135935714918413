<template>
  <div>
    <Header/>
    <router-view :key="componentKey"></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import config from '../config'
import { mapActions } from 'vuex'
import moment from "moment"
export default {
  name: 'IndexLayout',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      componentKey: 1
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'Auction') {
        this.componentKey = to.params.years
      }
    }
  },
  mounted() {
    this.getEndDate(config.contract_1years, 1)
    this.getEndDate(config.contract_10years, 10)
    this.getEndDate(config.contract_100years, 100)
  },
  methods: {
    ...mapActions({
      getStorage: 'auction/getStorage',
      getBigMapId: 'auction/getBigMapId'
    }),
    getEndDate(contract, year) {
     let params = {
       contract: contract,
     };
     this.getStorage(params).then((res) => {
       if (year === 1) {
         localStorage.setItem('1years', moment(res.auction_end_date).utcOffset(0, true).format())
       } else if (year === 10) {
         localStorage.setItem('10years', moment(res.auction_end_date).utcOffset(0, true).format())
       } else {
         localStorage.setItem('100years', moment(res.auction_end_date).utcOffset(0, true).format())
       }
     });
      this.getBigMapId(params).then((res) => {
        if (year === 1) {
          localStorage.setItem('1yearsBigMapId', res.ptr)
        } else if (year === 10) {
          localStorage.setItem('10yearsBigMapId', res.ptr)
        } else {
          localStorage.setItem('100yearsBigMapId', res.ptr)
        }
      });
    },
  }
}
</script>
<style>
</style>
