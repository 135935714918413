<template>
  <div>
    <footer class="footer">
      <div class="wrapper">
        <div class="footer-top">
          <div class="footer-text">
            Built by members of the <a href="">Kfar.io</a> team.
          </div>
          <div>Auction started {{this.startDate}}</div>
          <div class="footer-socials">
            <a href="#" class="footer-socials__link">
              <img src="../../public/facebook.svg" alt="Facebook">
            </a>
            <a href="#" class="footer-socials__link">
              <img src="../../public/linkedin.svg" alt="LinkedIn">
            </a>
            <a href="#" class="footer-socials__link">
              <img src="../../public/twitter.svg" alt="Twitter">
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  import axios from 'axios'
  import moment from "moment"
  import config from "@/config";
export default {
  name: 'Footer',
  data () {
    return {
      startDate: ''
    }
  },
  created() {
    axios.get(`https://api.ghostnet.tzkt.io/v1/contracts/${config.contract_1years}/`).then((res) => {
      this.startDate = moment(res.data.firstActivityTime).format('DD/MM/YYYY');
    })
  }
}
</script>
<style scoped lang="scss">
  .footer {
    border-top: 1px solid #A1BACC;
    padding: 16px 0;
    background: #fff;
    @media (max-width: 768px) {
      padding: 16px 0 12px;
    }
    .wrapper {
      flex-direction: column;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    &-text {
      color: #000000;
      font-size: 22px;
      line-height: 30px;
      @media (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 26px;
      }
    }
    &-socials {
      display: flex;
      align-items: center;
      &__link {
        height: 32px;
        &:hover {
          img {
            transform: scale(1.125);
          }
        }
        img {
          transition: .35s;
        }
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
    &-bot {
      padding-top: 14px;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      color: #979797;
      width: 100%;
      border-top: 1px solid rgba(161, 186, 204, 0.5);
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        padding-top: 10px;
      }
    }
  }
</style>
