const Explore = {
  namespaced: true,
  state: {
    transactions: [],
    after: ''
  },
  actions: {

  },
  mutations: {
    setImage(state, data) {
      console.log('data', data)
    },
    setTransactionsJson (state, data) {
      state.transactions.push(data)
    },
    setAfter (state, data) {
      state.after = data
    },
  },
  getters: {
    transactions: state => state.transactions
  }
}
export default Explore
