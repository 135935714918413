var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "footer-top"
  }, [_vm._m(0), _c('div', [_vm._v("Auction started " + _vm._s(this.startDate))]), _vm._m(1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-text"
  }, [_vm._v(" Built by members of the "), _c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("Kfar.io")]), _vm._v(" team. ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-socials"
  }, [_c('a', {
    staticClass: "footer-socials__link",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/facebook.svg"),
      "alt": "Facebook"
    }
  })]), _c('a', {
    staticClass: "footer-socials__link",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/linkedin.svg"),
      "alt": "LinkedIn"
    }
  })]), _c('a', {
    staticClass: "footer-socials__link",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/twitter.svg"),
      "alt": "Twitter"
    }
  })])]);
}]

export { render, staticRenderFns }