var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('router-link', {
    staticClass: "header-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "50px"
    },
    attrs: {
      "src": require("../../public/icon-mini.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "header-block"
  }, [_vm.isLogged ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hideNotificationsMenu,
      expression: "hideNotificationsMenu"
    }],
    staticClass: "header-notifications"
  }, [_c('button', {
    staticClass: "header-notifications__btn",
    on: {
      "click": function click($event) {
        return _vm.toggleNotificationsMenu();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/bell.svg"),
      "alt": "Notifications"
    }
  }), _vm.notificationActive.length ? _c('span', {
    attrs: {
      "id": "total-notifications"
    }
  }, [_vm._v(_vm._s(_vm.notificationActive.length))]) : _vm._e()]), _c('div', {
    class: [_vm.isNotificationsOpen ? 'header-notice open' : 'header-notice']
  }, [_c('div', {
    staticClass: "header-notice__head"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("header.notifications")))]), _c('button', {
    staticClass: "header-notice__clear not-empty",
    on: {
      "click": _vm.clearAll
    }
  }, [_vm._v(_vm._s(_vm.$t("header.clear_all")))])]), _c('div', {
    staticClass: "header-notice__body"
  }, [_c('div', {
    staticClass: "header-notice__list not-empty"
  }, _vm._l(_vm.notification, function (not, i) {
    return _c('div', {
      key: i,
      staticClass: "header-notice__item",
      style: not.isActive ? '' : 'opacity: 0.5',
      on: {
        "click": function click($event) {
          return _vm.changeNotification(not);
        }
      }
    }, [_c('div', {
      staticClass: "header-notice__inner"
    }, [_c('div', {
      staticClass: "header-notice__auction"
    }, [_vm._v(_vm._s(not.year) + " YEAR Auction")]), _c('div', {
      staticClass: "header-notice__status win"
    }, [_vm._v("Your win ")])])]);
  }), 0), _c('div', {
    staticClass: "header-notice__empty empty"
  }, [_vm._v(_vm._s(_vm.$t("header.no_notifications")))])])])]) : _vm._e(), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hideAccountMenu,
      expression: "hideAccountMenu"
    }],
    staticClass: "header-account"
  }, [_c('button', {
    staticClass: "header-account__connect 23",
    class: _vm.isLogged ? 'logged' : '',
    on: {
      "click": function click($event) {
        [_vm.isLogged ? _vm.toggleAccountMenu() : _vm.plugInWallet()];
      }
    }
  }, [_vm.isLogged ? _c('span', {
    staticClass: "logged"
  }, [_c('span', {
    staticClass: "status"
  }), _c('span', [_vm._v(_vm._s(_vm.userAddress.substr(0, 3)) + "..." + _vm._s(_vm.userAddress.substr(_vm.userAddress.length - 4)))])]) : _c('span', {
    staticClass: "not-logged"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("header.connect_wallet")))]), _c('img', {
    staticClass: "not-logged",
    attrs: {
      "src": require("../../public/wallet.svg"),
      "alt": "Wallet"
    }
  })])]), !_vm.isLogged ? _c('div', {
    staticClass: "header-account__info not-logged",
    on: {
      "click": _vm.openModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/info.svg"),
      "alt": "Info"
    }
  }), _c('div', {
    staticClass: "header-account__bg"
  }, [_c('svg', {
    attrs: {
      "width": "41",
      "height": "34",
      "viewBox": "0 0 41 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.5 33.5C33.2483 33.5 40.5002 25.8573 40.5002 17C40.5002 8.12461 32.2356 0.5 22.5 0.5C17.6113 0.5 14.3585 2.23591 11.9014 4.32608C10.6815 5.36382 9.65949 6.48735 8.74127 7.50771C8.70366 7.54951 8.66625 7.5911 8.62903 7.63248C7.77326 8.58385 7.01901 9.42237 6.25527 10.0542C6.21325 10.0685 6.1584 10.0876 6.0923 10.1117C5.9332 10.1698 5.70833 10.2569 5.43952 10.375C4.90333 10.6105 4.185 10.9725 3.46328 11.4777C2.03202 12.4795 0.5 14.1107 0.5 16.5C0.5 19.0172 2.01242 20.772 3.44631 21.8683C4.16726 22.4196 4.88533 22.8184 5.42192 23.0794C5.69086 23.2102 5.91591 23.3072 6.07521 23.3721C6.10447 23.384 6.13153 23.3948 6.15625 23.4046C6.20005 23.4786 6.25906 23.5763 6.33322 23.6949C6.50968 23.9769 6.77214 24.3771 7.11966 24.8561C7.81418 25.8134 8.85141 27.0893 10.2245 28.3661C12.9682 30.9177 17.0841 33.5 22.5 33.5Z",
      "fill": "#414141",
      "stroke": "#414141"
    }
  })])])]) : _vm._e(), _vm.isLogged ? _c('div', {
    staticClass: "header-account__menu ",
    class: {
      open: _vm.isAccountOpen
    }
  }, [_c('router-link', {
    staticClass: "header-account__link",
    attrs: {
      "to": {
        name: 'Profile'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("header.profile")))]), _c('router-link', {
    staticClass: "header-account__link",
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("header.blockchain_explorer")))]), _c('button', {
    staticClass: "header-account__link",
    on: {
      "click": _vm.turnOffBasket
    }
  }, [_vm._v(_vm._s(_vm.$t("header.disconnect_wallet")))])], 1) : _vm._e()]), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeNavMenu,
      expression: "closeNavMenu"
    }]
  }, [_c('button', {
    staticClass: "header-menu",
    class: {
      open: _vm.isNavOpen
    },
    on: {
      "click": function click($event) {
        return _vm.toggleNavMenu();
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span')]), _c('div', {
    staticClass: "header-nav__wrapper",
    class: {
      open: _vm.isNavOpen
    }
  }, [_c('div', {
    staticClass: "header-nav"
  }, [_c('div', {
    staticClass: "header-account -logged"
  }, [!_vm.isLogged ? _c('div', {
    staticClass: "header-account__info not-logged",
    on: {
      "click": _vm.openModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/info.svg"),
      "alt": "Info"
    }
  }), _c('div', {
    staticClass: "header-account__bg"
  }, [_c('svg', {
    attrs: {
      "width": "41",
      "height": "34",
      "viewBox": "0 0 41 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.5 33.5C33.2483 33.5 40.5002 25.8573 40.5002 17C40.5002 8.12461 32.2356 0.5 22.5 0.5C17.6113 0.5 14.3585 2.23591 11.9014 4.32608C10.6815 5.36382 9.65949 6.48735 8.74127 7.50771C8.70366 7.54951 8.66625 7.5911 8.62903 7.63248C7.77326 8.58385 7.01901 9.42237 6.25527 10.0542C6.21325 10.0685 6.1584 10.0876 6.0923 10.1117C5.9332 10.1698 5.70833 10.2569 5.43952 10.375C4.90333 10.6105 4.185 10.9725 3.46328 11.4777C2.03202 12.4795 0.5 14.1107 0.5 16.5C0.5 19.0172 2.01242 20.772 3.44631 21.8683C4.16726 22.4196 4.88533 22.8184 5.42192 23.0794C5.69086 23.2102 5.91591 23.3072 6.07521 23.3721C6.10447 23.384 6.13153 23.3948 6.15625 23.4046C6.20005 23.4786 6.25906 23.5763 6.33322 23.6949C6.50968 23.9769 6.77214 24.3771 7.11966 24.8561C7.81418 25.8134 8.85141 27.0893 10.2245 28.3661C12.9682 30.9177 17.0841 33.5 22.5 33.5Z",
      "fill": "#414141",
      "stroke": "#414141"
    }
  })])])]) : _vm._e(), _c('button', {
    staticClass: "header-account__connect 2",
    class: _vm.isLogged ? 'logged' : '',
    on: {
      "click": function click($event) {
        [_vm.isLogged ? _vm.toggleAccountMenu() : _vm.plugInWallet()];
      }
    }
  }, [_vm.isLogged ? _c('span', {
    staticClass: "logged"
  }, [_c('span', {
    staticClass: "status"
  }), _c('span', [_vm._v(_vm._s(_vm.userAddress.substr(0, 3)) + "..." + _vm._s(_vm.userAddress.substr(_vm.userAddress.length - 4)))])]) : _c('span', {
    staticClass: "not-logged"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("header.connect_wallet")))]), _c('img', {
    staticClass: "not-logged",
    attrs: {
      "src": require("../../public/wallet.svg"),
      "alt": "Wallet"
    }
  })])]), !_vm.isLogged ? _c('div', {
    staticClass: "header-account__info not-logged",
    on: {
      "click": _vm.openModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../public/info.svg"),
      "alt": "Info"
    }
  }), _c('div', {
    staticClass: "header-account__bg"
  }, [_c('svg', {
    attrs: {
      "width": "41",
      "height": "34",
      "viewBox": "0 0 41 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.5 33.5C33.2483 33.5 40.5002 25.8573 40.5002 17C40.5002 8.12461 32.2356 0.5 22.5 0.5C17.6113 0.5 14.3585 2.23591 11.9014 4.32608C10.6815 5.36382 9.65949 6.48735 8.74127 7.50771C8.70366 7.54951 8.66625 7.5911 8.62903 7.63248C7.77326 8.58385 7.01901 9.42237 6.25527 10.0542C6.21325 10.0685 6.1584 10.0876 6.0923 10.1117C5.9332 10.1698 5.70833 10.2569 5.43952 10.375C4.90333 10.6105 4.185 10.9725 3.46328 11.4777C2.03202 12.4795 0.5 14.1107 0.5 16.5C0.5 19.0172 2.01242 20.772 3.44631 21.8683C4.16726 22.4196 4.88533 22.8184 5.42192 23.0794C5.69086 23.2102 5.91591 23.3072 6.07521 23.3721C6.10447 23.384 6.13153 23.3948 6.15625 23.4046C6.20005 23.4786 6.25906 23.5763 6.33322 23.6949C6.50968 23.9769 6.77214 24.3771 7.11966 24.8561C7.81418 25.8134 8.85141 27.0893 10.2245 28.3661C12.9682 30.9177 17.0841 33.5 22.5 33.5Z",
      "fill": "#414141",
      "stroke": "#414141"
    }
  })])])]) : _vm._e(), _vm.isLogged ? _c('div', {
    staticClass: "header-account__menu open"
  }, [_c('router-link', {
    staticClass: "header-account__link",
    attrs: {
      "to": {
        name: 'Profile'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("header.profile")))]), _c('router-link', {
    staticClass: "header-account__link",
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("header.blockchain_explorer")))]), _c('button', {
    staticClass: "header-account__link",
    on: {
      "click": _vm.turnOffBasket
    }
  }, [_vm._v(_vm._s(_vm.$t("header.disconnect_wallet")))])], 1) : _vm._e()]), _vm._l(_vm.routes, function (route) {
    return _c('div', {
      key: route.path
    }, [_c('router-link', {
      staticClass: "header-nav__link",
      attrs: {
        "to": route.to
      }
    }, [_vm._v(_vm._s(_vm.$t("header." + route.text_key)))])], 1);
  })], 2)])])])], 1)]), _vm.isModalOpen ? _c('Modal', {
    attrs: {
      "close": _vm.close
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }