import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './localization/i18n.js'
import './style/style.scss';
import gsap from "gsap/gsap-core";
// import VueSmoothScroll from 'vue2-smooth-scroll';
import Toasted from 'vue-toasted'
import VueLazyload from 'vue-lazyload'

// Vue.use(VueSmoothScroll);
Vue.use(VueLazyload)

Vue.use(require('vue-moment'));

gsap.config({
  nullTargetWarn: false
})

Vue.config.productionTip = false

Vue.use(Toasted)
Vue.use(Toasted, {})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
