<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      init: 'authWallet/init',
    })
  }
}
</script>
