import {  NetworkType  } from '@airgap/beacon-sdk'
export const CONTRACT_1YEARS = 'KT19g86W6467APFzDZLPVLZZjcWuVgczR3Ss';
export const CONTRACT_10YEARS = 'KT1K9EYBBXzQJ3NK7pwBYthNka2MbRc3vPpW';
export const CONTRACT_100YEARS = 'KT1T7oYCE4BXmXnbYHPSS3x4qSHnVJMkr2od';
export const CONTRACT_NFT = 'KT1JJzyYMG2t3kSE5rwFLXwniUY6DGf2RC9e';
// Mintery: KT1JJzyYMG2t3kSE5rwFLXwniUY6DGf2RC9e
// Deploing Year auction
// Auction 1: KT19g86W6467APFzDZLPVLZZjcWuVgczR3Ss
// Deploing 10 year auction
// Auction 10: KT1K9EYBBXzQJ3NK7pwBYthNka2MbRc3vPpW
// Deploing Century Auction
// Auction 100: KT1T7oYCE4BXmXnbYHPSS3x4qSHnVJMkr2od
// export const ENDPOINT = 'https://hangzhounet.api.tez.ie'
// export const NETWORK = NetworkType.HANGZHOUNET
// export const PER_PAGE_COUNT = 2

export default {
network: NetworkType.GHOSTNET,
contract_1years: CONTRACT_1YEARS,
contract_10years: CONTRACT_10YEARS,
contract_100years: CONTRACT_100YEARS,
win_count_1years: 100,
win_count_10years: 10,
win_count_100years: 1,
contract_nft: CONTRACT_NFT,
explorer: 'https://tzkt.io',
endpoint: NetworkType.HANGZHOUNET === NetworkType.MAINNET ? 'https://mainnet.api.tez.ie' : 'https://rpc.ghostnet.teztnets.xyz/',
// endpoint: NetworkType.HANGZHOUNET === NetworkType.MAINNET ? 'https://rpc.ithacanet.teztnets.xyz' : 'https://rpc.ithacanet.teztnets.xyz',
tzktapi: NetworkType.HANGZHOUNET === NetworkType.MAINNET ? 'https://api.tzkt.io' : 'https://api.hangzhou2net.tzkt.io',
walletOptions: {
  name: 'LAE',
  iconUrl: '/favicon-32x32.png',
  eventHandlers: {
    PERMISSION_REQUEST_SUCCESS: {
      handler: async (data) => {
        console.log('PERMISSION_REQUEST_SUCCESS', data)
      }
    },
    PERMISSION_REQUEST_SENT: {
      handler: async (data) => {
        console.log('PERMISSION_REQUEST_SENT', data)
      }
    },
    ACKNOWLEDGE_RECEIVED: {
      handler: async (data) => {
        console.log('ACKNOWLEDGE_RECEIVED', data)
      }
    },
    NO_PERMISSIONS: {
      handler: async (data) => {
        console.log('NO_PERMISSIONS', data)
      }
    },
    BROADCAST_REQUEST_SENT: {
      handler: async (data) => {
        console.log('BROADCAST_REQUEST_SENT', data)
      }
    },
    BROADCAST_REQUEST_SUCCESS: {
      handler: async (data) => {
        console.log('BROADCAST_REQUEST_SUCCESS', data)
      }
    },
    BROADCAST_REQUEST_ERROR: {
      handler: async (data) => {
        console.log('BROADCAST_REQUEST_ERROR', data)
      }
    }
  }
}
}
