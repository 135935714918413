import { http } from '../../api'
import config from '../../config'
import { getRank, getBigMap} from '../../scripts/auction'
import {getNftJson} from "../../scripts/arweave";


const Auction = {
  namespaced: true,
  state: {
    nftLists: [],
    userTransactions: [],
    transactions: [],
    storage: {}
  },
  actions: {
    async getStorage ({ commit }, params) {
      const res = await http.get(`/contracts/${params.contract}/storage`)
      commit('setStorage', res.data)
      return res.data
    },
    async getNft ({ commit }, params) {
      const res1 =  await http.get(`/contracts/${config.contract_nft}/bigmaps/ledger/keys?value=${params.address}`);
      if (res1.data && res1.data.length) {
        let nat = []
        let address = {}
        res1.data.map((item) => {
          nat.push(item.key)
          address[item.key] =  item.value
        })
        const res =  await http.get(`/contracts/${config.contract_nft}/bigmaps/token_metadata/keys?key.in=${nat.join()}&active=true&select.values=key,value`);
        commit('setNft', {data: res.data, address: address})
        return res.data
      }
    },
    async getBigMapId ({ commit }, params) {
      const res = await http.get(`/contracts/${params.contract}/bigmaps/all_bids/?select.fields=ptr`)
      commit('setBigMapId', res.data)
      return res.data
    },
    async getTransactions ({ commit }, params) {
      let ranks = ''
      if (params.target) {
        ranks = await getRank(params.target);
      }
      const resData = await getBigMap(params, ranks);
      commit('setTransactions', resData)
    },
    async getUserTransactions ({ commit }, params) {
      const resData = await getBigMap(params);
      commit('setUserTransactions', resData)
      return resData
    },
    async getPlaceTransactions ({ commit }, params) {
      const resData = await getBigMap(params);
      commit('setPlaceTransactions', resData)
      return resData
    },
  },
  mutations: {
    setNft (state, data) {
      state.nftLists = []
      data.data.map((item) => {
        let json = getNftJson(item[0], data.address)
        state.nftLists.push(json)
      })
    },
    setTransactions (state, data) {
      state.transactions = data
    },
    setBigMapId (state, data) {
      state.bigMaipId = data
    },
    setStorage (state, data) {
      state.storage = data
    },
    setUserTransactions (state, data) {
      state.userTransactions = data
    },
    withdrawUserTransactions (state, id) {
      state.userTransactions = state.userTransactions.map((item) => {
        if (item.key === id) {
          item.active = false
        }
        return item
      })
    },
    setPlaceTransactions (state, data) {
      console.log('Transactions', data)
    },
  },
  getters: {
    transactions: state => state.transactions
  }
}
export default Auction
