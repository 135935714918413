import i18n  from '../localization/i18n'

const Translation = {
  get defaultLocale() {
    return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
  },
  get supportedLocales() {
    return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
  },
  get currentLocale() {
    return i18n.locale
  },
  set currentLocale(locale) {
    i18n.locale = locale
  },
  getUserSupportedLocale() {
    const userPreferredLocale = Translation.getUserLocale()

    if (Translation.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (Translation.isLocaleSupported(userPreferredLocale.localeNoISO)) {
      return userPreferredLocale.localeNoISO
    }
    return Translation.defaultlocale
  },
  getUserLocale() {
    const locale = window.navigator.language || window.navigator.userLanguage || Translation.defaultLocale
    return {
      locale: locale,
      localeNoISO: locale.split('-')[0]
    }
  },
  setI18nLocaleInServices(locale) {
    Translation.currentLocale = locale
    document.querySelector('html').setAttribute('lang', locale)
    return locale
  },
  changeLocale(locale) {
    if (i18n.locale === locale) return Promise.resolve(locale)
    return Translation.loadLocaleFile(locale).then(() => {
      return Translation.setI18nLocaleInServices(locale)
    })
  },
  loadLocaleFile(locale) {
    return import(`../localization/locales/${locale}.json`)
  },
  isLocaleSupported(locale) {
    return Translation.supportedLocales.includes(locale)
  },
  routeMiddleware(to, from, next) {
    let locale = '';
    if (['ru', 'en'].includes(to.params.locale)){
      localStorage.setItem('lang', to.params.locale);
      locale =  to.params.locale
    } else {
      locale = localStorage.getItem('lang');
    }
    if (!Translation.isLocaleSupported(locale)) return next(Translation.getUserSupportedLocale());
    return Translation.changeLocale(locale).then(() => {
      next()
    })
  },
  redirectMiddleware(to, from, next) {
    let name = '';
    if (to.name) {
      name = to.name
    } else {
      name = 'Home'
    }
    next(Translation.i18nRoute({name: name}))
  },
  notFoundMiddleware(to, from, next) {
    let name = '';
    if (to.name) {
      name = to.name
    } else if (to.fullPath === '/ru/' || to.fullPath === '/en/' || to.fullPath === '/ru' || to.fullPath === '/en') {
      name = 'Home'
    } else {
      name = 'NotFound'
    }
    next(Translation.i18nRoute({name: name}))
  },
  i18nRoute(to) {
    return {
      ...to,
      params: {
        locale: this.currentLocale ? this.currentLocale : localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru',
        ...to.params
      }
    }
  }
}

export {
  Translation
}
