import {http} from "../../api";
import config from "../../config";


export function getNftJson(id, address) {
  let json = require(`../../../public/Lae-backup/json2/${id}.json`);
  json.image_url = require(`../../../public/Lae-backup/pics/${id}.png`)
  json.address = address[id]
  return json
}

export async function getTransactionIdsByAddressJson (year = 'all', season = '', limit = 12) {
  const winJson = await getWinAddress()
  let json = require(`../../../public/Lae-backup/arweave.json`);
  const data = []
  let count = 0
  for (let i = 0; i < json.length; i ++) {
    let item = json[i]
    if (winJson[item.id]) {
      item.address = winJson[item.id].address
    }
    if (count < limit) {
      if (year === 'all' && season === 'all') {
        data.push(item)
        count++
      } else {
        if (year && season && year !== 'all' && season !== 'all') {
          if (item.attributes && item.attributes[0].value === year && item.attributes[1].value === season) {
            data.push(item)
            count++
          }
        } else if (year && year !== 'all') {
          if (item.attributes && item.attributes[0].value === year) {
            data.push(item)
            count++
          }
        } else if (season && season !== 'all') {
          if (item.attributes && item.attributes[1].value === season) {
            data.push(item)
            count++
          }
        }
      }
    } else {
      break
    }
  }
  return data
}

export async function getWinAddress() {
  const res1 = await http.get(`/contracts/${config.contract_nft}/bigmaps/ledger/keys?value.ni=${config.contract_1years},${config.contract_10years},${config.contract_100years}`);
  const resData = {}
  if (res1.data && res1.data.length) {
    res1.data.map((item) => {
      resData[item.key] = {
        address: item.value
      }
    })
  }
  return resData
}
