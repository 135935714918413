import axios from 'axios'

export const baseURL = process.env.VUE_APP_WEB_API

const http = axios.create({
  baseURL,
  withCredentials: false
})

http.interceptors.request.use((config) => {
  return config
}, (error) => {
  console.log('error', error)
  return Promise.reject(error)
})

http.interceptors.response.use((response) => response, (error) => {
  console.log('error', error)
  return Promise.reject(error)
})

export {
  http
}
