import Vue from 'vue'
import VueRouter from 'vue-router'
import { Translation } from '../plugins/Translation'
import IndexLayout from '../layouts/IndexLayout.vue'

Vue.use(VueRouter)


function load(component) {
  return () => import(`../views/${component}.vue`)
}
const routes = [
  {
    path: "/",
    beforeEnter: Translation.redirectMiddleware,
  },
  {
    path: "/:name",
    beforeEnter: Translation.notFoundMiddleware,
  },
  {
    path: '/:locale',
    component: IndexLayout,
    beforeEnter: Translation.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'faq',
        name: 'Faq',
        component: load('Faq')
      },
      {
        path: 'about',
        name: 'About',
        component: load('About')
      },
      {
        path: 'auction/:years(|1|10|100)',
        name: 'Auction',
        component: load('Auction')
      },
      {
        path: "more-info",
        name: 'more-info',
        component: load('Auctions')
      },
      {
        path: 'explore',
        name: 'Explore',
        component: load('Explore')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: load('Profile')
      },
      {
        path: 'profile/:address',
        name: 'WalletInfo',
        component: load('WalletInfo')
      },
      {
        path: 'upload-image',
        name: 'UploadImage',
        component: load('UploadImage')
      },
      {
        path: '/404',
        name: 'NotFound',
        component: load('NotFound')
      },
      {
        path: '/:pathMatch(.*)',
        redirect: '404'
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "exact-active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router

