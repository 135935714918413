import Vue from 'vue'
import Vuex from 'vuex'
import authWallet from './modules/Wallet.js'
import Auction from './modules/Auction'
import Explore from './modules/Explore'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authWallet: authWallet,
    auction: Auction,
    explore: Explore,
  }
})
