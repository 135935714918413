<template>
  <div class="modal" @click="close">
    <div class="modal-content" @click.stop>
      <div class="modal-content__head">How to connect wallet?</div>
      <div class="modal-content__body">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </div>
      <div class="close" @click="closeModal">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.4525 13.0001L20.6256 6.82671C21.0273 6.42515 21.0273 5.77589 20.6256 5.37434C20.2241 4.97278 19.5748 4.97278 19.1733 5.37434L13 11.5478L6.82688 5.37434C6.42515 4.97278 5.77609 4.97278 5.37454 5.37434C4.97281 5.77589 4.97281 6.42515 5.37454 6.82671L11.5476 13.0001L5.37454 19.1735C4.97281 19.5751 4.97281 20.2243 5.37454 20.6259C5.57466 20.8262 5.83778 20.9268 6.10071 20.9268C6.36365 20.9268 6.62658 20.8262 6.82688 20.6259L13 14.4525L19.1733 20.6259C19.3736 20.8262 19.6365 20.9268 19.8994 20.9268C20.1624 20.9268 20.4253 20.8262 20.6256 20.6259C21.0273 20.2243 21.0273 19.5751 20.6256 19.1735L14.4525 13.0001Z" fill="#C2C2C2"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin);
export default {
  name: 'Modal',
  props: {
    close: {
      type: Function
    },
  },
  mounted() {
    gsap.to('.modal-content', {
      duration: 0.5,
      opacity: 1
    })
  },
  methods: {
    closeModal () {
      gsap.to('.modal', {
        duration: 0.5,
        opacity: 0
      })
      setTimeout(() => {
        this.close()
      },500)
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  width: 100%;
  flex: 1;
  height: 100%;
  background-color: rgb(17 17 17 / 0.84);
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .35s;
  &-content {
    opacity: 0.4;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    padding: 16px 20px 24px;
    max-width: 600px;
    margin: 0 20px;
    @media (max-width: 576px) {
      margin: 0 12px;
      padding: 12px 12px 18px;
    }
    &__head {
      margin-bottom: 12px;
      font-size: 30px;
      line-height: 36px;
      font-weight: bold;
      color: #333333;
      @media (max-width: 576px) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
    &__body {
      padding-top: 12px;
      border-top: 1px solid #E1E1E1;
      @media (max-width: 576px) {
        padding-top: 10px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    svg {
      transition: .35s;
      path {
        transition: .35s;
      }
    }
    &:hover {
      svg {
        path {
          fill: #ED7D37;
        }
      }
    }
    @media (max-width: 576px) {
      top: 12px;
      right: 12px;
    }
  }
}
</style>
