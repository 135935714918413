import config from '../../config'
import { http } from '../../api'
import moment from "moment"
const completed = {};
export async function getBigMap (params) {
  const contract1 = config.contract_1years
  const contract10 = config.contract_10years
  const contract100 = config.contract_100years
  let bigMapData = []
  if (params.id && params.sender) {
    let res = await http.get(`/bigmaps/${params.id}/keys?key=${params.sender}&select.fields=key,value,active`);
    const winners = await getStorageWinnersId(params.contract)
    res.data = await addParams(res.data, winners.ids, params.contract, params.myBids, winners.ranks, 'allUser')
    bigMapData = res.data
  } else if (params.userAddress) {
    const BigMapId1 = localStorage.getItem('1yearsBigMapId')
    const BigMapId10 = localStorage.getItem('10yearsBigMapId')
    const BigMapId100 = localStorage.getItem('100yearsBigMapId')

    const res1 = await http.get(`/bigmaps/${BigMapId1}/keys?key=${params.userAddress}&select.fields=key,value,active&sort.value=amount`);
    const winners1 = await getStorageWinnersId(contract1)
    res1.data =  await addParams(res1.data, winners1.ids, contract1, null,winners1.ranks, 'user')

    const res10 = await http.get(`/bigmaps/${BigMapId10}/keys?key=${params.userAddress}&select.fields=key,value,active&sort.value=amount`);
    const winners10 = await getStorageWinnersId(contract10)
    res10.data = await addParams(res10.data, winners10.ids, contract10, null,winners10.ranks , 'user')

    const res100 = await http.get(`/bigmaps/${BigMapId100}/keys?key=${params.userAddress}&select.fields=key,value,active&sort.value=amount`);
    const winners100 = await getStorageWinnersId(contract100)
    res100.data = await addParams(res100.data, winners100.ids, contract100, null,winners100.ranks, 'user')

    bigMapData = res1.data.concat(res10.data)
    bigMapData = bigMapData.concat(res100.data)
  } else if (params.target) {
    const res = await http.get(`/contracts/${params.target}/bigmaps/all_bids/keys`)
    const winners = await getStorageWinnersId(params.target)
    res.data = await addParams(res.data, winners.ids, params.target, null,  winners.ranks, 'keys')
    bigMapData = res.data
  }
  return bigMapData
}

export async function getStorageWinnersId (contract) {
  const array = []
  const rankObj = {}
  const res = await http.get(`/contracts/${contract}/storage`)
  if (res.data && res.data.active_bids) {
    Object.entries(res.data.active_bids).sort(function (a, b) {
      if (+a[1].amount < +b[1].amount) {
        return 1
      }
      if (+a[1].amount > +b[1].amount) {
        return -1
      }
      return 0
    })
    if (res.data.active_bids) {
      Object.values(res.data.active_bids).map((item, index) => {
        rankObj[item.id] = index + 1
        completed[item.id] = item.token_id
        array.push(item.id)
      });
    }
  } else if (res.data.winner) {
    rankObj[res.data.winner] = 1
    array.push(res.data.winner)
  }

  return {ids: array, ranks: rankObj}
}

export async function getRank (contract) {
  const res = await http.get(`/contracts/${contract}/storage/history?limit=101`)
  const resData = res.data
  const winners = []
  resData.map((item, index) => {
    if (item.value.active_bids) {
      if (resData[index + 1]) {
        Object.entries(item.value.active_bids).map(([kay2, winner2]) => {
          Object.entries(resData[index + 1].value.active_bids).map(([ kay ,winner1]) => {
            if (winner1.bid_id === winner2.bid_id) {
              let obj = null
              if (+kay > +kay2) {
                obj = {
                  key: winner1.bid_id,
                  rank: (+kay - +kay2),
                  type: 'plus',
                }
              } else if (+kay < +kay2) {
                obj = {
                  key: winner1.bid_id,
                  rank: -(+kay2 - +kay),
                  type: 'minus',
                }
              }
              if (obj) {
                winners.push(obj)
              }
            }
          })
        })
      }
    }
  })

  let arr = []
  for (let i = 0; i < winners.length; i++) {
    let obj = {}
    for (let j = 0; j < winners.length; j++) {
      if (winners[j] && winners[i]) {
        if (j !== i) {
          if (winners[j].key === winners[i].key) {
            winners[i].rank = winners[i].rank + winners[j].rank
            if (winners[i].rank >= 0 ) {
              winners[i].type = 'plus'
            } else {
              winners[i].type = 'minus'
            }
            obj = winners[i];
            winners[j] = null
          }
        }
      }
    }
    if (obj && obj.key) {
      arr.push(obj)
    }
  }
  return arr
}

export async function addParams (data, ids, contract, myBids, ranks, type) {
  let year = null
  if (contract === config.contract_1years) {
    year = 1
  } else if (contract === config.contract_10years) {
    year = 10
  } else {
    year = 100
  }

  const newData = []
  data.map((item) => {
    Object.entries(item.value).map(([key, val]) => {
      let rank = 'Out of ranks'
      if (ranks[key]) {
        rank = ranks[key]
      }
      let obj = {
        active: completed[key] ? false : true,
        rank: rank,
        key: key,
        value: {
          amount: val.amount,
          season: val.season,
          ts: val.ts,
          bidder: item.key
        }
      }
      newData.push(obj)
    })
  });


  data = [...newData]

  data = data.sort(function (a, b) {
    if (+a.value.amount < +b.value.amount) {
      return 1
    }
    if (+a.value.amount > +b.value.amount) {
      return -1
    }
    return 0
  })

  data = data.sort(function (a, b) {
    if (+a.rank > +b.rank) {
      return 1
    }
    if (+a.rank < +b.rank) {
      return -1
    }
    return 0
  })

  data = data.map((obj)=> {
    let status = false
    let auctionFinished = false
    obj.value.amount = +obj.value.amount
    if(ids.includes(obj.key)) {
        status = true
        if (type === 'user') {
          let date = localStorage.getItem(year + 'years')
          let countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
          let t = moment(new Date());
          if (countdown.diff(t) && countdown.diff(t) <= 0) {
            let not = JSON.parse(localStorage.getItem('user_notification'))
            if (!not) {
              not = []
            }
            let data2 = obj
            data2.isActive = true
            data2.year = year
            if (not.length && not[0]) {
              const isExists = not.find((item) => {
                return (item.key + item.year) === (obj.key + year)
              })
              if (!isExists) {
                not.push(data2)
              }
            } else {
              if (data2.key) {
                not.push(data2)
              }
            }
            if (not.length) {
              localStorage.setItem('user_notification', JSON.stringify(not))
            }
          }
        }
    }

    let rank = 'Out of ranks'
    if (ranks[obj.key]) {
      rank = ranks[obj.key]
    }

    let date = localStorage.getItem(year + 'years')
    let countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
    let t = moment(new Date());
    if (countdown.diff(t) && countdown.diff(t) <= 0) {
      auctionFinished = true
    }

    return { ...obj, year: year, statusWin: status, auctionFinished: auctionFinished, rank}
  })
  if (myBids) {
    data = data.filter((item) => item.statusWin === false)
  }
  return data
}
